<script setup>
import { cssProp } from "@/utils/css";
import { useValidation } from "@/composables/useValidation";

const props = defineProps(["modelValue", "rules", "type", "hasFocus"]);
const emit = defineEmits([
  "update:modelValue",
  "update:hasFocus",
  "blur",
  "focus",
  "enter",
  "input",
]);
const attrs = useAttrs();

const el = shallowRef(null);
const text = shallowRef("");
const uniqid = getCurrentInstance().uid;

watchEffect(() => (text.value = props.modelValue));
watchEffect(() => emit("update:modelValue", text.value));

const hasFocus = shallowRef(false);
const isEmpty = computed(() => !text.value || text.value?.toString().trim()?.length == 0);
const isActive = computed(
  () => hasFocus.value || !isEmpty.value || computedAttrs.persistentLabel
);

const computedAttrs = reactive({
  focused: "focused" in attrs,
  clearable: "clearable" in attrs,
  type: computed(() => attrs.type || props.type || "input"),
  readonly: computed(() => attrs.readonly || props.readonly || false),
  validateOnInput: "validate-on-input" in attrs || false,
  hideDetails: "hide-details" in attrs || false,
  persistentLabel: "persistent-label" in attrs || false,
});

const computedStyle = computed(() => ({
  width: cssProp(props.width),
  color: cssProp(attrs.color),
}));

// validation
const form = inject("form", null);

const validationModel = useValidation(text, props.rules);
const { hasErrors, errors, validate } = validationModel;

onMounted(() => {
  if (form) form?.register(validationModel);

  if (computedAttrs.focused) setTimeout(() => focus(), 500);
});

onUnmounted(() => {
  if (form) form.unregister(validationModel);
});

const focus = () => el.value.focus();
const blur = () => el.value.blur();

function handleInput() {
  if (computedAttrs.validateOnInput) validate();

  emit("input");
}

function handleFocus() {
  hasFocus.value = true;
  emit("focus");
  emit("update:hasFocus", true);
}

function handleBlur() {
  hasFocus.value = false;
  emit("blur");
  emit("update:hasFocus", false);
  validate();
}

function handleKeyPress(e) {
  if (e.key == "Enter") {
    blur();
    emit("enter");
  }
}

function clear() {
  text.value = "";
}

// expose
defineExpose({
  hasErrors,
  errors,
  validate,
  focus,
  blur,
});
</script>
<template>
  <div class="v-text-field" :class="{ error: hasErrors }" :style="computedStyle">
    <div class="flex-width align-center width-100" style="display: flex">
      <slot name="prepend" :hasFocus="hasFocus"></slot>

      <div class="flex-width">
        <v-label
          v-if="attrs.label"
          class="label width-100"
          :active="isActive"
          :error="hasErrors"
        >
          {{ transl(attrs.label) }}
        </v-label>

        <input
          v-model="text"
          ref="el"
          spellcheck="false"
          autocomplete="off"
          :id="uniqid"
          :type="computedAttrs.type"
          :maxlength="attrs.maxlength"
          :placeholder="transl(attrs.placeholder)"
          :readonly="attrs.readonly"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
          @keypress="handleKeyPress"
        />
      </div>
      <Transition name="appear" appear>
        <v-btn
          v-if="computedAttrs.clearable && !isEmpty"
          @click="clear()"
          icon="sm"
          class="mr-2"
        >
          <!-- <font-awesome-icon icon="fa-solid fa-xmark" size="lg" /> -->
          <img style="width: 20px" src="/icons/input-closed.svg" />
        </v-btn>
      </Transition>
      <slot name="append"></slot>
    </div>

    <slot name="error">
      <v-error :class="{ active: hasErrors }" v-if="!computedAttrs.hideDetails">
        {{ transl(errors[0]) }}
      </v-error>
    </slot>
  </div>
</template>

<style scoped>
input {
  font-family: "Google Sans", Roboto, Helvetica, Arial, sans-serif;
  color: var(--text-color);
  font-size: 20px;
  height: 50px;
  width: 100%;
  background-color: transparent;
  border: 0;
}

input:focus {
  outline: none;
}

input[type="password"] {
  font: small-caption;
  font-size: 36px;
}

.v-text-field {
  display: flex;
  flex: 1;
  justify-items: stretch;
  align-items: stretch;
  position: relative;
  height: 100%;
  max-height: 70px;
  background-color: var(--forms-bg);
  border-radius: 5px;
  /* border: 1.5px solid var(--forms-border); */
  padding: 10px 20px;
  transition: border-color 0.2s ease-in-out;
}

.v-text-field.error {
  border: 1px solid rgb(255, 104, 28) !important;
}

.v-text-field:not(.error):focus-within {
  border-color: var(--forms-border-focus);
  outline: none;
}

.v-text-field:not(:focus-within):hover {
  outline: 1.5px solid var(--grey-light5);
}

.label {
  left: -5px;
  text-indent: 0px;
  z-index: 2;
}

.v-text-field[flat] {
  background-color: transparent;
  border-radius: 0px;
  border: none;
  padding: 0px;
}

.v-text-field[flat]:focus-within {
  border-color: transparent;
  box-shadow: none;
}

.v-text-field[flat]:hover {
  border-color: transparent;
  outline: none;
}

.v-text-field[dense] {
  height: 100%;
  max-height: 60px;
}

.v-text-field[rounded] {
  border-radius: 30px;
}

.appear-enter-from {
  transition: all 0.2s ease;
  transform: rotate(0deg) scale(0.5);
  opacity: 0.3;
}

.appear-enter-to,
.appear-leave-from {
  transition: all 0.2s ease;
  transform: rotate(90deg) scale(1);
  opacity: 1;
}

.appear-leave-to {
  transition: all 0.2s ease;
  transform: rotate(180deg) scale(0);
  opacity: 0;
}
</style>
